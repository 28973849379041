<template>
  <b-flix-layout variant="main-header">
    <b-flix-h1 variant="white" style="display: block; margin-bottom: 20px">
       {{ category }}
    </b-flix-h1>
    <b-flix-h2 variant="white">
      <flixIcon :id="icon" /> {{ title }}
    </b-flix-h2>
  </b-flix-layout>
</template>
<script>
export default {
  components: {},
  props: {
    items: {
      type: Object,
      default () { return {} },
      required: true
    }
  },
  data () {
    return {
      title: this.$t('message.dashboard'),
      icon: 'dashboard',
      category: this.$t('company.name')
    }
  },
  computed: {

  },
  mounted () {
    this.getTitle()
  },
  methods: {
    getTitle () {
      const items = this.items
      const route = this.$route
      let title = ''
      let icon = ''
      let category = ''
      Object.keys(items).forEach(function (key) {
        if (title) {
          return false
        }
        Object.keys(items[key].items).forEach(function (key2) {
          if (title) {
            return false
          }
          if (items[key].items[key2].link.name === route.name) {
            title = items[key].items[key2].name
            icon = items[key].items[key2].icon
            category = items[key].name
          }
        })
      })
      if (title) {
        this.title = title
      }
      if (title) {
        this.icon = icon
      }
      if (category) {
        this.category = category
      }
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
